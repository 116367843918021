
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_1e959_281",
  "arrow-outer": "awsui_arrow-outer_xjuzf_1e959_285",
  "arrow-inner": "awsui_arrow-inner_xjuzf_1e959_285",
  "refresh": "awsui_refresh_xjuzf_1e959_320",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_1e959_330",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_1e959_330",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_1e959_333",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_1e959_333",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_1e959_336",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_1e959_336",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_1e959_336",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_1e959_336",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_1e959_339",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_1e959_339",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_1e959_339",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_1e959_339",
  "body": "awsui_body_xjuzf_1e959_483",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_1e959_522",
  "has-dismiss": "awsui_has-dismiss_xjuzf_1e959_526",
  "dismiss": "awsui_dismiss_xjuzf_1e959_531",
  "dismiss-control": "awsui_dismiss-control_xjuzf_1e959_539",
  "header-row": "awsui_header-row_xjuzf_1e959_543",
  "header": "awsui_header_xjuzf_1e959_543",
  "content": "awsui_content_xjuzf_1e959_568",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_1e959_576",
  "container": "awsui_container_xjuzf_1e959_720",
  "container-body": "awsui_container-body_xjuzf_1e959_728",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_1e959_771",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_1e959_776",
  "fixed-width": "awsui_fixed-width_xjuzf_1e959_779",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_1e959_783",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_1e959_790",
  "container-arrow": "awsui_container-arrow_xjuzf_1e959_802",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_1e959_806",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_1e959_806",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_1e959_823",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_1e959_823",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_1e959_840",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_1e959_840",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_1e959_840",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_1e959_840",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_1e959_856",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_1e959_860",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_1e959_864",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_1e959_1",
  "root": "awsui_root_xjuzf_1e959_1052",
  "root-filtering-token": "awsui_root-filtering-token_xjuzf_1e959_1088",
  "trigger": "awsui_trigger_xjuzf_1e959_1092",
  "overflow-ellipsis": "awsui_overflow-ellipsis_xjuzf_1e959_1099",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_1e959_1107",
  "trigger-type-filtering-token": "awsui_trigger-type-filtering-token_xjuzf_1e959_1143",
  "popover-inline-content": "awsui_popover-inline-content_xjuzf_1e959_1147"
};
  